// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from '@modules/react';
import { Redirect, useLocation, useHistory } from '@modules/react-router';
import notification from '@modules/antd/lib/notification';
import Spin from '@modules/antd/lib/spin';

import { loginWithThirdParty } from './server';
import consts from './consts';

const LoginWithThirdPartyHOC = (core: any) => (): JSX.Element => {
    const location = useLocation();
    const history = useHistory();
    const search = new URLSearchParams(location.search);

    useEffect(() => {
        const provider = search.get('provider');
        const code = search.get('code');
        const process = search.get('process');
        const scope = search.get('scope');
        const authParams = search.get('auth_params');

        if (provider) {
            let tokenURL = `/api/auth/social/${provider}/login/token/`;
            if (location.pathname.includes(consts.OIDC_LOGIN_PATH)) {
                tokenURL = `/api/auth/oidc/${provider}/login/token/`;
            } else if (location.pathname.includes(consts.SAML_LOGIN_PATH)) {
                tokenURL = `/api/auth/saml/${provider}/login/token/`;
            }

            loginWithThirdParty(core, tokenURL, code, authParams, process, scope)
                .then(() => window.location.reload())
                .catch((exception: Error) => {
                    if (exception.message.includes('Unverified email')) {
                        history.push('/auth/email-verification-sent');
                    } else {
                        history.push('/auth/login');
                        notification.error({
                            message: 'Could not log in with the provided account',
                            description: exception.toString(),
                        });
                    }
                });
        }
    }, []);

    return (
        <div className='cvat-login-page cvat-spinner-container'>
            <Spin size='large' className='cvat-spinner' />
        </div>
    );
}

export default LoginWithThirdPartyHOC;
