// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from '@modules/react';
import { useLocation } from '@modules/react-router';
import { Row, Col } from '@modules/antd/lib/grid';
import Spin from '@modules/antd/lib/spin';
import notification from '@modules/antd/lib/notification';

import SigningLayout, { formSizes } from '@root/components/signing-common/signing-layout';
import { useIsMounted } from '@root/utils/hooks';

import consts from './consts';
import { getSocialAuthMethods, getSSOLoginURL as getFromServerSSOLoginURL } from './server';
import { SocialAuthMethodSnakeCase, SocialAuthMethod, SelectionSchema } from './social-auth';
import LoginWithSSOForm from './login-with-sso-form';

const LoginWithSSOHOC = (core: any) => (): JSX.Element => {
    const isMounted = useIsMounted();
    const [fetching, setFetching] = useState(false);
    const [SSOConfiguration, setSSOConfiguration] = useState<SocialAuthMethod | null>(null);
    const [SSOLoginURL, setSSOLoginURL] = useState<string | null>(null);

    const location = useLocation();
    const search = new URLSearchParams(location.search);

    useEffect(() => {
        const iss = search.get('iss');
        setFetching(true);
        if (!iss) {
            getSocialAuthMethods(core)
                .then((methods: Record<string, SocialAuthMethodSnakeCase>) => {
                    const sso = methods[consts.SSO_PROVIDER_KEY];
                    if (!sso) {
                        throw new Error('Authentication methods does not contain setup SSO');
                    }

                    const SSO = new SocialAuthMethod(sso);
                    if (SSO.selectionSchema === SelectionSchema.LOWEST_WEIGHT) {
                        getFromServerSSOLoginURL(core).then((loginURL: string) => {
                            if (isMounted()) {
                                setSSOLoginURL(loginURL);
                                setFetching(false);
                            }
                        }).catch((exception: Error) => {
                            notification.error({
                                message: 'Could not setup identity provider',
                                description: exception.toString(),
                            });
                        });
                    } else {
                        setSSOConfiguration(SSO);
                        if (isMounted()) {
                            setFetching(false);
                        }
                    }
                }).catch((exception: Error) => {
                    notification.error({
                        message: 'Could not receive authentication methods',
                        description: exception.toString(),
                    });
                });
        } else {
            getFromServerSSOLoginURL(core, undefined, iss).then((loginURL: string) => {
                if (isMounted()) {
                    setSSOLoginURL(loginURL);
                    setFetching(false);
                }
            }).catch((exception: Error) => {
                notification.error({
                    message: 'Could not setup identity provider',
                    description: exception.toString(),
                });
            });
        }
    }, []);

    useEffect(() => {
        if (SSOLoginURL) {
            window.open(SSOLoginURL, '_self');
        }
    }, [SSOLoginURL]);

    if (!fetching && !SSOLoginURL && SSOConfiguration?.selectionSchema === SelectionSchema.EMAIL_ADDRESS) {
        return (
            <SigningLayout>
                <Col {...formSizes.wrapper}>
                    <Row justify='center'>
                        <Col {...formSizes.form}>
                            <LoginWithSSOForm
                                fetching={fetching}
                                onSubmit={(email: string): void => {
                                    setFetching(true);
                                    getFromServerSSOLoginURL(core, email).then((loginURL: string) => {
                                        if (isMounted()) {
                                            setSSOLoginURL(loginURL);
                                            setFetching(false);
                                        }
                                    }).catch((exception: Error) => {
                                        notification.error({
                                            message: 'Could not setup identity provider',
                                            description: exception.toString(),
                                        });
                                        setFetching(false);
                                    });
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </SigningLayout>
        );
    }
    return (
        <div className='cvat-login-page cvat-spinner-container'>
            <Spin size='large' className='cvat-spinner' />
        </div>
    );
};

export default LoginWithSSOHOC;
