// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from '@modules/react';
import { Route } from '@modules/react-router';
import notification from '@modules/antd/lib/notification';
import { useIsMounted } from '@root/utils/hooks';
import { User } from '@root/cvat-core-wrapper';
import { PluginEntryPoint, ComponentBuilder } from '@root/components/plugins-entrypoint';

import consts from './consts';
import { getSocialAuthMethods } from './server';
import { SocialAuthMethod, SocialAuthMethodCamelCase, SocialAuthMethodSnakeCase } from './social-auth';
import SocialAccountLink from './social-account-link';
import LoginWithThirdPartyHOC from './login-with-third-party';
import LoginWithSSOHOC from './login-with-sso';

function SocialSSOFormHOC(core: any) {
    return (): JSX.Element | null => {
        const { backendAPI } = core.config;
        const [methods, setMethods] = useState<SocialAuthMethod[]>([]);
        const isMounted = useIsMounted();

        useEffect(() => {
            getSocialAuthMethods(core)
                .then((methods: SocialAuthMethodSnakeCase[]) => {
                    const classMethods = Object.entries(methods)
                        .map(([provider, value]) => new SocialAuthMethod({ ...value, provider }));
                    if (isMounted() && classMethods.length) {
                        setMethods(classMethods);
                    }
                }).catch((error) => {
                    if (isMounted()) {
                        notification.error({
                            message: 'Could not receive authentication methods',
                            description: error.toString(),
                        });
                    }
                });
        }, []);

        if (methods.length) {
            return (
                <div className='cvat-social-authentication'>
                    {methods.filter((item: SocialAuthMethodCamelCase) => item.isEnabled)
                        .map((item: SocialAuthMethodCamelCase) => (
                            <SocialAccountLink
                                key={item.provider}
                                icon={item.icon}
                                href={(item.provider !== consts.SSO_PROVIDER_KEY) ?
                                    `${backendAPI}/auth/social/${item.provider}/login/` :
                                    '/auth/sso/select-identity-provider/'}
                                className={`cvat-social-authentication-${item.provider}`}
                            >
                                {`Continue with ${item.publicName}`}
                            </SocialAccountLink>
                        ))}
                </div>
            )
        }

        return null;
    };
}

const SocialSSOPlugin: ComponentBuilder = ({ dispatch, core, REGISTER_ACTION, REMOVE_ACTION }) => {
    const SocialSSOComponent = SocialSSOFormHOC(core);
    const SocialLoginRouter = (): JSX.Element => {
        return (
            <Route
                key='cvat-plugin-social-login-router'
                exact
                path={consts.SOCIAL_LOGIN_PATH}
                component={LoginWithThirdPartyHOC(core)}
            />
        )
    };

    const OIDCLoginRouter = (): JSX.Element => {
        return (
            <Route
                key='cvat-plugin-oidc-login-router'
                exact
                path={consts.OIDC_LOGIN_PATH}
                component={LoginWithThirdPartyHOC(core)}
            />
        )
    };

    const SAMLLoginRouter = (): JSX.Element => {
        return (
            <Route
                key='cvat-plugin-saml-login-router'
                exact
                path={consts.SAML_LOGIN_PATH}
                component={LoginWithThirdPartyHOC(core)}
            />
        )
    };

    const SSOSelectIdPRouter = (): JSX.Element => {
        return (
            <Route
                key='cvat-plugin-sso-router'
                exact
                path='/auth/sso/select-identity-provider/'
                component={LoginWithSSOHOC(core)}
            />
        )
    };

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'loginPage.loginForm',
            component: SocialSSOComponent,
            data: {
                shouldBeRendered: (_: unknown, { credential }: { credential: string }) => {
                    return !credential;
                }
            }
	    }
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'router',
            component: SocialLoginRouter,
            data: {
                shouldBeRendered: ({ user }: { user: User }) => {
                    return !(user && user.isVerified);
                }
            }
	    }
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'router',
            component: OIDCLoginRouter,
            data: {
                shouldBeRendered: ({ user }: { user: User }) => {
                    return !(user && user.isVerified);
                }
            }
	    }
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'router',
            component: SAMLLoginRouter,
            data: {
                shouldBeRendered: ({ user }: { user: User }) => {
                    return !(user && user.isVerified);
                }
            }
	    }
    });

    dispatch({
        type: REGISTER_ACTION,
        payload: {
            path: 'router',
            component: SSOSelectIdPRouter,
            data: {
                shouldBeRendered: ({ user }: { user: User }) => {
                    return !(user && user.isVerified);
                }
            }
	    }
    });

    return {
        name: consts.PLUGIN_NAME,
        destructor: () => {
            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'loginPage.loginForm',
                    component: SocialSSOComponent,
                }
            });

            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'router',
                    component: SocialLoginRouter,
                }
            });

            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'router',
                    component: OIDCLoginRouter,
                }
            });

            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'router',
                    component: SAMLLoginRouter,
                }
            });

            dispatch({
                type: REMOVE_ACTION,
                payload: {
                    path: 'router',
                    component: SSOSelectIdPRouter,
                }
            });
        },
    }
}

function register() {
    if (Object.prototype.hasOwnProperty.call(window, 'cvatUI')) {
        (window as any as { cvatUI: { registerComponent: PluginEntryPoint } })
            .cvatUI.registerComponent(SocialSSOPlugin);
    }
};

window.addEventListener('plugins.ready', register, { once: true });
