// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

export async function getSocialAuthMethods(core: any): Promise<any> {
    const response = await core.server.request('/api/auth/social');
    return response.data;
}

export async function getSSOLoginURL(core: any, email?: string, iss?: string): Promise<string> {
    const response = await core.server.request('/api/auth/sso/login-url/', {
        params: { ...(email ? { email } : {}), ...(iss ? { iss } : {}) },
    });
    return response.data.url;
}

export async function loginWithThirdParty(
    core: any,
    tokenURL: string,
    code: string | null,
    authParams: string | null,
    process: string | null,
    scope?: string | null,
): Promise<void> {
    const data = {
        ...(code ? { code } : {}),
        ...(process ? { process } : {}),
        ...(scope ? { scope } : {}),
        ...(authParams ? { auth_params: authParams } : {}),
    };
    let authenticationResponse = null;

    authenticationResponse = await core.server.request(tokenURL, {
        data,
        method: 'POST',
    });

    await core.server.setAuthData(authenticationResponse);
}
