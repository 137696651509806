// Copyright (C) 2023-2024 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

export default {
    PLUGIN_NAME: 'Social & SSO Auth',
    SSO_PROVIDER_KEY: 'sso',
    SOCIAL_LOGIN_PATH: '/auth/login-with-social-app',
    OIDC_LOGIN_PATH: '/auth/login-with-oidc',
    SAML_LOGIN_PATH: '/auth/login-with-saml',
};
