// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

export enum SelectionSchema {
    EMAIL_ADDRESS = 'email_address',
    LOWEST_WEIGHT = 'lowest_weight',
}

export interface SocialAuthMethodCamelCase {
    provider: string;
    publicName: string;
    isEnabled: boolean;
    icon: string | null;
    selectionSchema?: SelectionSchema;
}

export interface SocialAuthMethodSnakeCase {
    public_name: string;
    is_enabled: boolean;
    icon: string | null;
    provider: string;
    selection_schema?: SelectionSchema;
}

export class SocialAuthMethod {
    #provider: SocialAuthMethodCamelCase['provider'];
    #publicName: SocialAuthMethodCamelCase['publicName'];
    #isEnabled: SocialAuthMethodCamelCase['isEnabled'];
    #icon: SocialAuthMethodCamelCase['icon'];
    #selectionSchema: SocialAuthMethodCamelCase['selectionSchema'];

    constructor(initialData: SocialAuthMethodSnakeCase) {
        this.#provider = initialData.provider;
        this.#publicName = initialData.public_name;
        this.#isEnabled = initialData.is_enabled;
        this.#icon = initialData.icon;
        this.#selectionSchema = initialData.selection_schema;
        this.#provider = initialData.provider;
    }

    public get provider() {
        return this.#provider;
    }

    public get publicName() {
        return this.#publicName;
    }

    public get isEnabled() {
        return this.#isEnabled;
    }

    public get icon() {
        return this.#icon;
    }

    public get selectionSchema() {
        return this.#selectionSchema;
    }
}
